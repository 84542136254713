<template>
  <div class="navbar-wrapper">
    <div class="navbar">
      <router-link
        v-for="item in links"
        :key="item.path"
        :to="`/${item.path}`"
        class="item"
      >
        {{ item.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  data: () => ({
    links: [
      { title: 'Features', path: 'features' },
      { title: 'Pricing', path: 'pricing' },
      { title: 'Contact', path: 'contact' },
      { title: 'About', path: 'about' },
    ]
  })
};
</script>