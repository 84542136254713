import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#5085a5",
        secondary: "#424242",
        accent: "#8fc1e3",
        lightAccent: '#add3f1',
        error: "#EC3D3D",
        info: "#2196F3",
        success: "#008040",
        warning: "#FFC107",
        mainGrey: "#8CA3A9",
        lightGrey: "#ACACAC",
        mainBlue: "#212840",
        headerBlue: "#37405B",
        lightBlue: "#67BED3",
        mainGreen: "#73DCAC",
        mainTurquoise: "#6ED3EA",
        mainRed: "#EC3D3D",
        grey: "#F2F2F2",
        mainOrange: "#E0BF10",
        lightBlack: "#79848c",
        borderGrey: "#E2E5E6",
        placeholder: "#A7A1A1",
        buttonBg: "#008040",
        explaintaionText: "#787676",
      },
    },
  },
});
