<template>
  <v-app>
    <HeaderNav />
    <router-view />
    <Footer />
    <GoTopButton
      :animate="true"
    >
      <v-icon>
        mdi-arrow-up
      </v-icon>
    </GoTopButton>
  </v-app>
</template>

<script>
import Footer from '@/components/Footer';
import HeaderNav from '@/components/HeaderNav';
import GoTopButton from "vue-go-top-button";
import "vue-go-top-button/dist/lib/vue-go-top-button.min.css";

export default {
  name: "App",
  components: {
    Footer,
    HeaderNav,
    GoTopButton,
  },
};
</script>

<style lang="scss"  src="./App.scss"></style>
