<template>
  <div class="footer">
    <div class="line" />
    <div class="items">
      <div class="item">
        <div class="item__main">
          FEATURES
        </div>
        <div v-for="item in featuresItems"
             :key="item.anchor"
             class="item__second"
        >
          <router-link :to="{ name: 'features' }"
                       @click.native="goToAnchor(item.anchor)"
          >
            {{ item.title }}
          </router-link>
        </div>
      </div>
      <div class="item">
        <div class="item__main">
          HELP
        </div>
        <router-link to="/pricing" tag="div" class="item__second">
          Pricing
        </router-link>
        <router-link to="/about" tag="div" class="item__second">
          About
        </router-link>

        <div class="item__second">
          <a href="https://app.dev-precontrax.quintagroup.com/faq"
             target="_blank"
          >
            FAQs
          </a>
        </div>
      </div>
      <div class="item">
        <div class="item__main">
          CONTACT DETAILS
        </div>
        <div class="item__second">
          <div class="icon-wrapper">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
              />
            </svg>
          </div>
          <div>
            1 Sheikh Zayed Road, DUBAI UAE
          </div>
        </div>
        <div class="item__second">
          <div class="icon-wrapper">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
              />
            </svg>
          </div>
          <div>
            (123) 456 7890
          </div>
        </div>
        <div class="item__second">
          <div class="icon-wrapper">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
          </div>
          <div>
            precontrax@help.com
          </div>
        </div>

        <div class="item__second">
          <div class="icon-wrapper">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
              />
            </svg>
          </div>
          <div>
            https://precontrax.com
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    featuresItems: [
      { 
        title: 'Projects', anchor: 'projects' 
      },
      { 
        title: 'Dashboard', anchor: 'dashboard' 
      },
      { 
        title: 'Control Room', anchor: 'control-room' 
      },
      { 
        title: 'Areas', anchor: 'areas'
      },
      { 
        title: 'Scope of works', anchor: 'scope-of-works'
      },
      { 
        title: 'Workflows', anchor: 'workflows'
      },
      { 
        title: 'Etendering', anchor: 'etendering'
      },
      { 
        title: 'Payments', anchor: 'payments'
      },
      { 
        title: 'Directory', anchor: 'directory'
      },
    ]
  }),
  methods: {
    goToAnchor(anchor) {
      this.$nextTick(() => {
        this.$vuetify.goTo(`#${anchor}`, {
          offset: 67,
        });
      })
    }
  }
};
</script>