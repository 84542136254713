import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('@/views/Features'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/ContactUs'),
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/Pricing'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({
      behavior: 'smooth',
    });
    
  },
});

export default router;
